.checkTitleRow:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, transparent calc(100% - 26px), white);
  pointer-events: none;
}
