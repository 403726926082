.appleScrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
}

.appleScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0;
}

.appleScrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 2px solid #f1f1f1;
  background: rgba(160, 160, 160, 0.7);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.appleScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
